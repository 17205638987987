<template>
  <section class="section">
    <div class="container">
      <b-loading
        v-model="loading"
        :is-full-page="false"
        class="is-primary"
      >
      </b-loading>
      <div v-if="formId">
        <b-button
          tag="router-link"
          :to="{ name: 'professionalaccess-req' }"
          icon-left="chevron-left"
          type="is-text"
        >
          {{ $t('details.backToRequests') }}
        </b-button>
        <h1 class="is-size-3 mb-0">
          {{ $t('details.requestNumber', [formId.toUpperCase()]) }}
        </h1>
        <component
          v-for="(step, stepIndex) in reviewSteps"
          :key="stepIndex"
          :is="`page-${step.component}`"
          class="page-confirmation__readonly"
        >
        </component>
      </div>
    </div>
  </section>
</template>

<script>
import api from '@/api';
import { mapActions, mapState } from 'vuex';
import { getReqDraftModel, getReqDraftDirectorFiles } from '@/utils/helper';

export default {
  name: 'ReqDetails',
  components: {
    PageBeneficiary: () => import('@/components/page/req/PageBeneficiary'),
    PageNeq: () => import('@/components/page/req/PageNeq'),
    PageOtherIndividual: () => import('@/components/page/req/PageOtherIndividual'),
    PageReqDirector: () => import('@/components/page/req/PageReqDirector'),
    PageValidateInfo: () => import('@/components/page/req/PageValidateInfo')
  },
  data () {
    return {
      loading: true,
      formId: null
    };
  },
  computed: {
    ...mapState('form', [
      'currentForm',
      'steps',
      'model'
    ]),
    reviewSteps () {
      return this.steps.length ? this.steps.slice(0, this.steps.findIndex(step => step.component === 'reqConfirmation')) : [];
    }
  },
  mounted () {
    this.formId = this.$route.params.id;
    Promise.all([api.getReqFormById(this.formId), api.getReqAttachmentsByFormId(this.formId)])
      .then(([reqFormResp, reqAttachmentsResp]) => {
        this.setViewModeReadOnly();
        this.setForm(reqFormResp.data.formType);
        this.setModel(getReqDraftModel(reqFormResp.data.form));
        this.setDirectorFiles(getReqDraftDirectorFiles(reqAttachmentsResp.data, this.model.reqDirector));
        this.updateSteps();

        this.loading = false;
      })
      .catch(() => {
        this.formId = null;
        this.$router.push('/');
      });
  },
  methods: {
    ...mapActions('form', [
      'setDirectorFiles',
      'setForm',
      'setModel',
      'updateSteps'
    ]),
    ...mapActions('viewMode', [
      'setViewModeReadOnly'
    ])
  }
};
</script>
